<template>
    <Modal
        v-model="show"
        footer-hide
        class="edit-auth-modal"
        class-name="vertical-center-modal"
        :width="900"
        :closable="false"
    >
        <p class="head">
            <span class="icon"></span>
            <span class="text">{{ customerName }}</span>
            <span class="role-name">角色名称：{{ roleName }}</span>
        </p>
        <div class="content">
            <Tabs :value="activeTab">
                <TabPane class="tab tab-one" label="功能授权" name="0">
                    <AuthTree :symbol="0" :data="funcAuth" title="功能权限" ref="funcTree"></AuthTree>
                    <!-- <AuthTree :symbol="1" :data="dataAuth" title="数据权限"></AuthTree> -->
                </TabPane>
                <TabPane class="tab" label="报表授权" name="1">
                    <AuthTree :symbol="2" :data="reportAuth" ref="reportTree"></AuthTree>
                </TabPane>
            </Tabs>
        </div>

        <div class="btn-box" style="text-align: center;">
            <Button type="primary" style="width: 144px;" @click="handleConfirm">确定</Button>
            <Button
                type="info"
                class="cancel"
                style="width: 144px; margin-left: 10px; background: #f2f2f2; color: #333; border-color: transparent;"
                @click="handleCancel"
                >取消</Button
            >
        </div>
    </Modal>
</template>

<script>
import AuthTree from '../../component/authTree';
import { reportAuthApi, funcAuthModifyApi } from '@/api/roleAndAccount';

export default {
    name: 'createModal',
    components: {
        AuthTree,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        funcAuth: {
            type: Array,
            default() {
                return [];
            },
        },
        dataAuth: {
            type: Array,
            default() {
                return [];
            },
        },
        reportAuth: {
            type: Array,
            default() {
                return [];
            },
        },
        roleName: String,
        roleId: Number,
        customerName: String
    },
    data() {
        return {
            activeTab: '0',
            reportData: [
                {
                    id: 2,
                    title: '宏观模块',
                    loading: false,
                    children: [],
                },
                {
                    id: 3,
                    title: '城市模块',
                    loading: false,
                    children: [],
                },
                {
                    id: 4,
                    title: '土地模块',
                    loading: false,
                    children: [],
                },
                {
                    id: 5,
                    title: '企业模块',
                    loading: false,
                    children: [],
                },
                {
                    id: 17,
                    title: '金融模块',
                    loading: false,
                    children: [],
                },
            ],
        };
    },
    computed: {
        show: {
            get() {
                return this.$store.state.personal.isShowEditAuthModal;
            },
            set(val) {
                this.$store.state.personal.isShowEditAuthModal = val;
            },
        },
    },
    methods: {
        handleCancel() {
            this.show = false;
        },
        handleConfirm() {
            this.show = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.edit-auth-modal {
    /deep/ .vertical-center-modal {
        display: flex;
        align-items: center;
        justify-content: center;

        .ivu-modal {
            top: 0;
        }

        .ivu-modal-body {
            padding: 0;
            padding-bottom: 30px;
            height: 100%;
        }

        .head {
            width: 100%;
            padding-top: 20px;
            padding-left: 20px;
            display: flex;
            align-items: center;
            position: relative;

            .icon {
                display: inline-block;
                width: 4px;
                height: 23px;
                background-color: #00b6ff;
                position: relative;

                &::after {
                    content: '';
                    border: 4px solid transparent;
                    border-left-color: #00b6ff;
                    position: absolute;
                    top: 50%;
                    right: -7px;
                    margin-top: -4px;
                }
            }

            .text {
                margin-left: 6px;
                font-size: 18px;
                font-weight: bold;
                line-height: 20px;
                color: #333;
            }

            .role-name {
                position: absolute;
                right: 20px;
            }
        }

        .content {
            display: block;
            margin-bottom: 30px;
            padding: 30px 20px 0;

            .tab {
                display: flex;
                justify-content: space-between;
            }
        }

        .btn-box {
            text-align: center;

            .cancel:focus {
                box-shadow: none;
            }
        }
    }
}
</style>
