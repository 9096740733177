<template>
	<Modal v-model="show" footer-hide class="create-modal" class-name="vertical-center-modal" :width="600"
		:closable="false" :mask-closable="false">
		<p class="head">
			<span class="icon"></span>
			<span class="text">重置密码</span>
		</p>
		<div class="content">
			<Form :model="form" :label-width="100" class="auth-form" :rules="rules" ref="form">
				<FormItem label="新密码" prop="newPassword">
					<Input type="password" placeholder="请输入" v-model="form.newPassword"
						style="width: 460px; margin-right: 10px; padding: 0"></Input>
				</FormItem>
				<!-- <FormItem label="原始密码" prop="rawPassword">
                    <Input
                        type="password"
                        placeholder="请输入"
                        v-model="form.rawPassword"
                        style="width: 460px;margin-right:10px;padding: 0;"
                    ></Input>
                </FormItem>

                <FormItem class="verify" label="短信验证码" prop="code">
                    <Input v-model="form.code" style="width: 190px;margin-right:10px;padding: 0;"></Input>
                    <Button
                        @click.native.prevent="handleMessage"
                        type="primary"
                        class="messageBtn"
                        :disabled="checkoutTime"
                        circle
                        >{{ checkoutTime ? `${timeOut}s` : '发送' }}</Button
                    >
                </FormItem> -->

				<FormItem style="text-align: center" :label-width="0">
					<Button type="info" style="width: 140px;height: 40px;" @click="submit('form')">确定</Button>
					<Button type="default" class="cancel" style="width: 140px;margin-left: 10px;height: 40px;"
						@click="handleCancel">取消</Button>
				</FormItem>
			</Form>
		</div>
	</Modal>
</template>

<script>
	import {
		detailApi,
		updatePassword
	} from "@/api/roleAndAccount";
	import {
		getPhoneCode
	} from "@/api/user/user";

	export default {
		name: "resetPassword",
		props: {
			id: Number,
			phone: String,
		},
		data() {
			const self = this;

			return {
				form: {
					newPassword: "",
					// rawPassword: '',
					// code: ''
				},
				rules: {
					newPassword: [{
						required: true,
						message: "此项必填",
						trigger: "blur"
					}],
					// rawPassword: [{ required: true, message: '此项必填', trigger: 'blur' }],
					// code: [{ required: true, message: '此项必填', trigger: 'blur' }]
				},

				timeOut: 60,
				checkoutTime: false,
				timer: "",
				userId: this.$store.state.userId
			};
		},
		computed: {
			show: {
				get() {
					return this.$store.state.personal.isShowResetPassModal;
				},
				set(val) {
					this.$store.state.personal.isShowResetPassModal = val;
				},
			},
		},
		methods: {
			handleCancel() {
				this.form = {
					newPassword: "",
					rawPassword: "",
					code: "",
				};
				this.show = false;
			},
			// handleMessage() {
			//   this.checkoutTime = true;
			//   getPhoneCode({
			//     phone: this.phone,
			//   }).then((res) => {
			//     this.$msg.success("remind",4029)
			//     this.timer = setInterval(() => {
			//       if (this.timeOut == 0) {
			//         clearInterval(this.timer);
			//         this.checkoutTime = false;
			//         this.timeOut = 60;
			//         return;
			//       }
			//       this.timeOut--;
			//     }, 1000);
			//   });
			// },
			submit(name) {
				this.$refs[name].validate((valid) => {
					if (valid) {
						updatePassword({
							// id: parseInt(this.$store.state.userId),
							id: this.id,
							pwd: this.form.newPassword,
							rawPassword: this.form.rawPassword,
							verifyCode: this.form.code,
						}).then((res) => {
							this.$msg.success({
								text: "密码重置成功"
							});
							this.form = {
								newPassword: "",
								rawPassword: "",
								code: "",
							};
							this.show = false;
						});
					} else {
						this.$msg.error({
							text: "请输入新密码"
						});
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.create-modal {
		/deep/ .vertical-center-modal {
			display: flex;
			align-items: center;
			justify-content: center;

			.ivu-modal {
				top: 0;
			}

			.ivu-modal-body {
				padding: 0;
				height: 100%;
			}

			.head {
				width: 100%;
				padding-top: 20px;
				padding-left: 20px;
				display: flex;
				align-items: center;

				.icon {
					display: inline-block;
					width: 4px;
					height: 23px;
					background-color: #00b6ff;
					position: relative;

					&::after {
						content: "";
						border: 4px solid transparent;
						border-left-color: #00b6ff;
						position: absolute;
						top: 50%;
						right: -7px;
						margin-top: -4px;
					}
				}

				.text {
					margin-left: 6px;
					font-size: 18px;
					font-weight: bold;
					line-height: 20px;
					color: #333;
				}
			}

			.content {
				padding-top: 30px;
				padding-left: 20px;
				padding-right: 20px;

				.auth-form {
					width: 100%;

					/deep/ .ivu-form-item {
						margin-bottom: 20px;

						&.role-item {
							.ivu-form-item-content {
								margin: 0 !important;

								.ivu-radio-group {
									width: 100%;

									.ivu-radio-group-item {
										margin-right: 40px;
									}
								}
							}
						}

						&.verify {
							.ivu-form-item-content {
								display: flex;
								align-items: center;

								.verify-img {
									height: 32px;
								}
							}
						}
					}
				}
			}

			.cancel:focus {
				box-shadow: none;
			}
		}
	}
</style>
