<template>
	<Modal v-model="show" footer-hide class="create-modal" :title='title' class-name="vertical-center-modal"
		:width="700" :mask-closable="false">
		<div class="modal-content">
			<Form :model="detail" :label-width="80" class="create-form" :rules="createRules" ref="editForm">
				<FormItem label="姓名" prop="nickname">
					<Input v-model="detail.nickname" placeholder="请输入" disabled></Input>
				</FormItem>
				<FormItem label="手机号码" prop="phone">
					<Input v-model="detail.phone" placeholder="请输入" disabled></Input>
				</FormItem>
				<FormItem label="密码" prop="password">
					<Button size='default' type="info" @click="handleUpdatePassword">重置</Button>
				</FormItem>
				<FormItem label="性别" prop="sex">
					<RadioGroup v-model="detail.sex">
						<Radio v-for="item in genderList" :label="item.value" :key="item.value">{{item.label}}
						</Radio>
					</RadioGroup>
				</FormItem>
				<FormItem label="职务/部门" prop="userDepartment">
					<Input v-model="detail.userDepartment" placeholder="请输入"></Input>
				</FormItem>
				<hr
					v-if='detail.userAduiltResult == 1 || (customerInfo.customerState ==0 || customerInfo.customerState ==3)'
					style="background-color: gray;border: none;height: 1px;opacity: 0.5;width: 580px;position: relative;left: 50%;transform: translateX(-50%);">
				<FormItem v-if='detail.userAduiltResult == 1' style='margin-top: 40px;' label="账号状态" prop="state">
					<RadioGroup v-model="detail.state">
						<Radio label="1">可用</Radio>
						<Radio label="0">不可用</Radio>
					</RadioGroup>
				</FormItem>
			<!-- 	<FormItem label="账号授权" prop="roleId">
					<Button type="info" @click="addRole">点击授权</Button>
					<div>
						<span>{{detail.roleName === '' ? '请选择角色':`角色名字：${detail.roleName}`}}</span>
					</div>
				</FormItem> -->
				<!-- 屏蔽有效日期选项 -->
				<!-- <div v-if="customerInfo.customerState ==0 || customerInfo.customerState ==3">
					<FormItem label="有效日期">
						{{detail.accountBeginDate | formatDate }}至{{ detail.accountEndDate|formatDate }}
					</FormItem>
					<FormItem>
						<Button :disabled="detail.accountDelayCount>=2" type="primary" style="width: 144px"
							@click="accountDelay">延长试用</Button><span
							style="margin-left: 30px;">{{`试用次数${detail.accountDelayCount}/${maxTimes}`}}</span>
					</FormItem>
				</div> -->
				<FormItem style="text-align: center;margin-top: 60px;" :label-width="0">
					<Button type="info" style="width: 140px;height: 40px;" @click="handleSubmit('editForm')">确定</Button>
					<Button type="default" class="cancel" style="width: 140px;height: 40px;margin-left: 20px;"
						@click="handleCancel()">取消</Button>
				</FormItem>
			</Form>
			<reset-password :id="id" :phone="detail.phone"></reset-password>
		</div>
	</Modal>
</template>

<script>
	import {
		detailApi,
		editAccount,
		customer_role_list
	} from "@/api/roleAndAccount";
	import ResetPassword from "./resetPassword";
	import util from "@/utils/utils2";
	export default {
		name: "createModal",
		components: {
			ResetPassword,
		},
		filters: {
			formatDate: function(time) {
				if (time != null && time != "") {
					let date = new Date(time);
					return util.formatDate(date, "yyyy-MM-dd hh:mm:ss");
				} else {
					return "";
				}
			},
		},


		props: {
			title: {
				type: String,
				default: "",
			},
			id: Number,
		},
		data() {
			return {
				genderList: [{
						value: '0',
						label: "男",
					},
					{
						value: '1',
						label: "女",
					},
				],
				maxTimes: 2,
				showUserRoleList: false,
				permissionsRoleDataList: [],
				detail: {
					username: "",
					nickname: "",
					phone: "",
					sex: "",
					userWorktime: "",
					password: "",
					userDepartment: "",
					userWechat: "",
					email: "",
					state: 0,
					roleId: '',
					roleName: '',
					// accountDelayCount: 0,
					// accountBeginDate: '',
					// accountEndDate: '',
				},

				createRules: {
					// userDepartment: [{
					// 	required: true,
					// 	message: "此项必填",
					// 	trigger: "blur"
					// }, ],
					state: [{
						required: true,
						message: "此项必填",
						trigger: "blur"
					}],
					nickname: [{
						required: true,
						message: "此项必填",
						trigger: "blur"
					}],
					phone: [{
						required: true,
						message: "此项必填",
						trigger: "blur"
					}],
				},
			};
		},
		computed: {
			show: {
				get() {
					return this.$store.state.personal.isShowAccountEditModal;
				},
				set(val) {
					this.$store.state.personal.isShowAccountEditModal = val;
				},
			},
			isShowResetPassModal: {
				get() {
					return this.$store.state.personal.isShowResetPassModal;
				},
				set(val) {
					this.$store.state.personal.isShowResetPassModal = val;
				},
			},
			customerInfo() {
				let str = localStorage.getItem('customerInfo')
				return JSON.parse(str)
			},
		},
		watch: {
			show(value) {
				if (value) {
					detailApi(this.id).then((res) => {
						this.detail = res
						this.getPackageList()
					});

				}
			},
		},
		methods: {
			// accountDelay() {
			// 	if (this.detail.accountDelayCount < 2) {
			// 		this.detail.accountDelayCount++
			// 		let unit = this.customerInfo.customerAccountDateTimeUnit
			// 		let addTime
			// 		if (unit == 0) {
			// 			//日
			// 			addTime = 1 * 1000 * 60 * 60 * 24
			// 		} else if (unit == 1) {
			// 			//月
			// 			addTime = 30 * 1000 * 60 * 60 * 24
			// 		} else if (unit == 2) {
			// 			//年
			// 			addTime = 365 * 1000 * 60 * 60 * 24
			// 		}
			// 		this.detail.accountEndDate = this.detail.accountEndDate + addTime
			// 	}
			// },
			async getPackageList() {
				let params = {
					current: 1,
					size: 9999,
					customerId: this.customerInfo.id
				}
				let data = await customer_role_list(params)
				this.permissionsRoleDataList = data.records
			},
			addRole() {
				this.showUserRoleList = true
			},
			sureUserRole() {
				this.permissionsRoleDataList.forEach(item => {
					if (item.id == this.detail.roleId) {
						this.detail.roleName = item.roleName
					}
				})
				this.showUserRoleList = false
			},
			handleSubmit(name) {
				const detail = this.detail;
				this.$refs[name].validate((valid) => {
					if (valid) {
						editAccount({
							id: this.id,
							email: detail.email,
							sex: detail.sex,
							state: detail.state,
							phone: detail.phone,
							userDepartment: detail.userDepartment,
							userWechat: detail.userWechat,
							userWorktime: detail.userWorktime,
							roleId: detail.roleId,
							roleName: detail.roleName,
							// accountDelayCount: detail.accountDelayCount,
							// accountBeginDate: detail.accountBeginDate,
							// accountEndDate: detail.accountEndDate,
						}).then((res) => {
							this.show = false;
							this.$msg.success({
								text: "修改成功"
							});
							this.$emit("done");
						}).catch(err => {
							if (err.code == -1) {
								this.$msg.error({
									text: err.data
								});
							}else{
								this.$msg.error({
									text: "修改失败"
								});
							}
						})
					} else {
						// this.$msg.success({
						// 	type: "remind",
						// 	code: 4033
						// });
						this.$msg.error({
							text: '输入项有误，请检查后重新输入'
						});
					}
				});
			},
			handleCancel() {
				this.show = false;
			},
			handleUpdatePassword() {
				this.isShowResetPassModal = true;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.create-modal {
		/deep/ .vertical-center-modal {
			display: flex;
			align-items: center;
			justify-content: center;

			.ivu-modal {
				top: 0;
			}

			.ivu-modal-body {
				padding: 0;
				height: 100%;
			}

			.head {
				width: 100%;
				padding-top: 20px;
				padding-left: 20px;
				display: flex;
				align-items: center;

				.icon {
					display: inline-block;
					width: 4px;
					height: 23px;
					background-color: #00b6ff;
					position: relative;

					&::after {
						content: "";
						border: 4px solid transparent;
						border-left-color: #00b6ff;
						position: absolute;
						top: 50%;
						right: -7px;
						margin-top: -4px;
					}
				}

				.text {
					margin-left: 6px;
					font-size: 18px;
					font-weight: bold;
					line-height: 20px;
					color: #333;
				}
			}

			.content {
				padding-top: 30px;
				padding-left: 20px;
				padding-right: 20px;

				.create-form {
					width: 100%;

					.row {
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;

						.col {
							flex: 1;
						}
					}
				}

				.ivu-input[disabled] {
					color: #333;
				}
			}

			.cancel:focus {
				box-shadow: none;
			}
		}
	}

	.modal-content {
		padding: 40px 130px;
	}

	.mainContent {
		padding: 0 50px;

		.RadioGroupClass {
			width: 100%;
			max-height: 120px;
			overflow-y: auto;
			border: 1px solid rgba($color: #999999, $alpha: 0.5);
			padding: 10px;
			border-radius: 8px;
			margin: 20px 0;
		}
	}

	/deep/ .vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}
</style>
