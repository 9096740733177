<template>
  <div class="auth-tree">
    <div class="top">
      <span class="title">{{ title }}</span>
      <!-- <Icon type="ios-arrow-down" /> -->
    </div>

    <div class="tree-content">
      <Tree
        :data="treeData"
        show-checkbox
        @on-check-change="handleCheckChange"
      ></Tree>
    </div>
  </div>
</template>

<script>
import { funcAuthApi, reportAuthApi } from "@/api/roleAndAccount";

export default {
  name: "authTree",
  props: {
    title: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    symbol: Number,
  },
  data() {
    return {
      noDataList: [],
    };
  },
  computed: {
    treeData() {
      return this.data;
    },
    selectedAuth: {
      get() {
        return this.$store.state.personal.selectedAuth;
      },
      set(val) {
        this.$store.state.personal.selectedAuth = val;
      },
    },
  },
  mounted() {},
  methods: {
    async loadData(item, callback) {
      if (this.noDataList.includes(item.title)) {
        // this.$Message.warning("暂无数据");
        this.$msg.error({ text: "暂无数据" });

        callback({
          children: [],
        });
        return;
      }

      let data;
      if (this.symbol === 0) {
        data = await this.initFuncList(item);
      } else if (this.symbol === 1) {
        data = this.initDataList(item);
      } else if (this.symbol === 2) {
        data = await this.initReportList(item);
      }

      callback(data);
    },
    initReportList(item) {
      return new Promise((resolve) => {
        reportAuthApi({
          modelName: item.title,
        }).then((res) => {
          if (!Array.isArray(res.children)) {
            // this.$Message.warning("暂无数据");
            this.$msg.error({ text: "暂无数据" });

            this.noDataList.push(item.title);
            resolve();
          }

          let children = [];
          const changeData = (children) => {
            children.forEach((child) => {
              child.title = child.indexName;
              if (Array.isArray(child.children) && child.children.length > 0) {
                changeData(child.children);
              }
            });
          };

          changeData(res.children);
          res.title = res.indexName;

          resolve(res.children);
        });
      });
    },
    handleCheckChange(data) {

      const node = data[data.length - 1];
      if (node) {
        node.expand = true;
      }
      this.selectedAuth = data.map((item) => ({
        id: item.id,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-tree {
  width: 420px;

  .top {
    width: 100%;
    height: 30px;
    padding: 0 10px;
    border: 1px solid #c4c6cf;
    border-radius: 3px 3px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
  }

  .tree-content {
    height: 545px;
    padding-top: 13px;
    padding-left: 20px;
    border: 1px solid #dcdee3;
    border-top: none;
    border-radius: 0 0 3px 3px;
    overflow: auto;
  }
}
</style>
