<template>
	<Modal v-model="show" footer-hide class="create-modal" class-name="vertical-center-modal" :width="600"
		:closable="false" :mask-closable="false">
		<p class="head">
			<span class="icon"></span>
			<span class="text">账号授权</span>
		</p>
		<div class="content">
			<Form :model="detail" :label-width="80" class="auth-form" ref="detail">
				<FormItem label="手机号" prop="account">
					<Input v-model="detail.username" style="width: 480px; margin-right: 10px; padding: 0"
						disabled></Input>
				</FormItem>
				<FormItem label="姓名" prop="name">
					<Input v-model="detail.nickname" style="width: 480px; margin-right: 10px; padding: 0"
						disabled></Input>
				</FormItem>
				<FormItem label="部门/职位" prop="jobs">
					<Input v-model="detail.userDepartment" style="width: 480px; margin-right: 10px; padding: 0"
						disabled></Input>
				</FormItem>

				<FormItem class="role-item" prop="role">
					<RadioGroup v-model="roleId">
						<Radio v-for="item in roleList" :key="item.id" :label="item.id">{{
              item.roleName
            }}</Radio>
					</RadioGroup>
				</FormItem>

				<FormItem style="text-align: center">
					<Button type="primary" style="width: 144px" @click="submit('detail')">确定</Button>
					<Button type="info" class="cancel" style="
              width: 144px;
              margin-left: 10px;
              background: #f2f2f2;
              color: #333;
              border-color: transparent;
            " @click="handleCancel">取消</Button>
				</FormItem>
			</Form>
		</div>
	</Modal>
</template>

<script>
	import {
		getRoleList,
		detailApi,
		userAuthApi
	} from "@/api/roleAndAccount";

	export default {
		name: "createModal",
		props: {
			title: {
				type: String,
				default: "",
			},
			id: Number,
			selfRoleId: Number,
		},
		data() {
			return {
				roleList: [],
				detail: {},
				roleId: 0,
			};
		},
		watch: {
			show(val) {
				if (val) {

					detailApi(this.id).then((res) => {
						this.detail = res;
					});
					getRoleList({
						current: 0,
						size: 10000,
					}).then((res) => {
						this.roleList = res.records;
						this.roleId = this.selfRoleId;
					});
				}
			},
		},
		computed: {
			show: {
				get() {
					return this.$store.state.personal.isShowAuthModal;
				},
				set(val) {
					this.$store.state.personal.isShowAuthModal = val;
				},
			},
		},
		mounted() {
		},
		methods: {
			handleCancel() {
				this.show = false;
			},
			submit(name) {
				this.$refs[name].validate((valid) => {
					if (valid) {
						userAuthApi({
							userId: this.id,
							roleId: this.roleId,
						}).then((res) => {
							this.$msg.success({
								text: "授权成功"
							});
							this.show = false;
							this.$parent.getAccount()
						});
					} else {
						this.$msg.error({
							type: "remind",
							code: 4033
						});
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.create-modal {
		/deep/ .vertical-center-modal {
			display: flex;
			align-items: center;
			justify-content: center;

			.ivu-modal {
				top: 0;
			}

			.ivu-modal-body {
				padding: 0;
				height: 100%;
			}

			.head {
				width: 100%;
				padding-top: 20px;
				padding-left: 20px;
				display: flex;
				align-items: center;

				.icon {
					display: inline-block;
					width: 4px;
					height: 23px;
					background-color: #00b6ff;
					position: relative;

					&::after {
						content: "";
						border: 4px solid transparent;
						border-left-color: #00b6ff;
						position: absolute;
						top: 50%;
						right: -7px;
						margin-top: -4px;
					}
				}

				.text {
					margin-left: 6px;
					font-size: 18px;
					font-weight: bold;
					line-height: 20px;
					color: #333;
				}
			}

			.content {
				padding-top: 30px;
				padding-left: 20px;
				padding-right: 20px;

				.auth-form {
					width: 100%;

					/deep/ .ivu-form-item {
						margin-bottom: 20px;

						&.role-item {
							.ivu-form-item-content {
								margin: 0 !important;

								.ivu-radio-group {
									width: 100%;

									.ivu-radio-group-item {
										margin-right: 40px;
									}
								}
							}
						}
					}

					.ivu-input[disabled] {
						color: #333;
					}
				}
			}

			.cancel:focus {
				box-shadow: none;
			}
		}
	}
</style>
