<template>
	<div class="my-account">
		<Header :title="customerName"></Header>
		<div class="search">
			<div class="left">
				<span class="name">姓名</span>
				<Input v-model="keyword" maxlength="15" show-word-limit placeholder="请输入" style="width: 250px" />
				<span class="name" style="margin-left: 30px;">手机号码</span>
				<Input type="number" maxlength="11" show-word-limit v-model="phone" placeholder="请输入手机号码" style="width: 250px" />
			</div>
			<div class="right">
				<Button class="btn" type="default" @click="getList(1)">查询</Button>
				<Button class="btn" type="primary" @click="createAccount">新建账号</Button>
			</div>
		</div>
		<div class="canUseaccount">
			已用普通账号数：{{`${userCount}`}}
		</div>
		<Table class="table" tooltip-theme="light" border :columns="columns" :data="data">
			<template slot-scope="{ row }" slot="option">
				<Button class="optionBtn" size='small' type="primary"
					@click="importPassword(row,'isShowAccountEditModal')">查看/编辑</Button>
				<Button class="optionBtn" size='small' type="primary"
					@click="openAuth(row,'showUserRoleList')">授权</Button>
				<ComfirmBtn info='删除' size='small' type="error" title='温馨提示' @comfirm='deleteAccount(row.id)'>
					<div slot="content">
						<div style="font-size: 18px;text-align: center;">
							请确认删除<span style="color: red;">{{row.nickname}}</span>这一账号？
						</div>
					</div>
				</ComfirmBtn>
			</template>
			<template slot-scope="{ row }" slot="is">
				<strong>{{ row.name }}</strong>
			</template>
		</Table>
		<Page style='margin-top: 20px;' :total="total" @on-change="pageChange" />

		<Modal v-model="showImportPassword" class-name='vertical-center-modal' title='密码验证' :mask-closable="false"
			:width='600' :footer-hide='true'>
			<div style="padding: 40px 100px;">
				<Form ref="valid" :model="valid" :label-width="60">
					<FormItem label="密码">
						<Input type="password" password v-model="valid.password" placeholder="请输入6位或以上数字/字母">
						</Input>
					</FormItem>
				</Form>
			</div>
			<div class="footer" style="text-align: center;margin-bottom: 40px;">
				<Button style="width: 140px;height: 40px;" type="info" @click="importPasswordOk">确认</Button>
			</div>
		</Modal>

		<Modal class-name='vertical-center-modal' v-model="showUserRoleList" title="账号授权" width="610" :closable='false'
			:mask-closable="false" :footer-hide='true'>
			<div class="mainContent">
				<Form ref="formInline" inline>
					<FormItem style='width: 47%;' :label-width="40" label="姓名">
						<Input :readonly="true" v-model="rowData.nickname" placeholder="请输入"></Input>
					</FormItem>
					<FormItem style='width: 47%;' :label-width="80" label="手机号码">
						<Input :readonly="true" v-model="rowData.phone" placeholder="请输入"></Input>
					</FormItem>
				</Form>
				<div class="roleList">
					角色列表
				</div>
				<RadioGroup class='RadioGroupClass' v-model="rowData.roleId">
					<Radio style='width: 45%;margin: 10px;' v-for="(item,index)  in permissionsRoleDataList"
						:key='index' :label="item.id">
						<span>{{item.roleName}}</span>
					</Radio>
				</RadioGroup>
			</div>

			<div class="footer" style="text-align: center;margin: 60px 0 40px;">
				<Button style="width: 140px;height: 40px;" type="info" @click="sureUserRole">确定</Button>
				<Button type="default" style="width: 140px;margin-left: 20px;height: 40px;" class="cancel"
					@click="cancleUserRole">取消</Button>
			</div>
		</Modal>

		<CreateModal :title="modalTitle" @done="getList(current)"></CreateModal>
		<EditAccount :show="isShowAccountEditModal" :title="modalTitle" :id="userId" @done="getList(current)">
		</EditAccount>
		<AuthModal :show="isShowAuthModal" :id="userId" :selfRoleId="roleId"></AuthModal>
		<EditAuthModal :dataAuth="dataAuthList" :funcAuth="funcAuthList" :reportAuth="reportAuthList"
			:show="isShowEditAuthModal" :roleName="selectedRow.roleName" :roleId="selectedRow.id"
			:customerName="customerName"></EditAuthModal>
	</div>
</template>

<script>
	import Header from "./component/Header";
	import CreateModal from "./modal/accountManage/createModal";
	import EditAccount from "./modal/accountManage/editModal ";
	import AuthModal from "./modal/accountManage/authModal";
	import EditAuthModal from "./modal/accountManage/editAuthModal";
	import ComfirmBtn from "@/components/Comfirm";
	import {
		get_data_user_page,
		detailApi,
		getRoleList,
		funcAuthApi,
		funcAuthIdApi,
		reportAuthApi,
		reportAuthIdApi,
		getRoleIdApi,
		deleteAccount,
		usableUserCount,
		customer_role_list,
		editAccount
	} from "@/api/roleAndAccount";
	import {
		getDetail
	} from "@/api/MyAccount";
	import {
		formatTime,
		formatDate
	} from "@/utils/index";
	import {
		getCode,
		getPhoneCode,
		verifyPwdAndCode,
		verifyPwd
	} from "@/api/user/user";


	export default {
		name: "myAccount",
		components: {
			Header,
			CreateModal,
			AuthModal,
			EditAccount,
			EditAuthModal,
			ComfirmBtn
		},
		data() {
			const self = this;
			return {
				rowData: {},
				showUserRoleList: false,
				permissionsRoleDataList: [],
				columns: [{
						title: "编号",
						key: "id",
						width: 68,
						align: "center",
					},
					// {
					//   title: "账号",
					//   key: "username",
					//   align: "center",
					//   width: 98,
					//   tooltip: true,
					//   resizable: true,
					// },
					{
						title: "姓名",
						key: "nickname",
						width: 80,
						align: "center",
						tooltip: true,

					},
					{
						title: "性别",
						key: "sex",
						width: 80,
						align: "center",
						tooltip: true,
						render: (h, params) => {
							return h("span", {}, params.row.sex == 0 ? "男" : params.row.sex == 1 ? '女' : params.row
								.sex);
						},
					},
					{
						title: "职务/部门",
						key: "userDepartment",
						align: "center",
						width: 120,
						tooltip: true,
					},
					{
						title: "手机号码",
						key: "phone",
						align: "center",
						width: 180,
						tooltip: true,
						resizable: true,
					},
					{
						title: "账号状态",
						key: "state",
						align: "center",
						width: 120,
						render: (h, params) => {
							return h("span", {}, params.row.state == 0 ? "不可用" : "可用");
						},
					},
					{
						title: "审核结果",
						key: "userAduiltResult",
						align: "center",
						width: 120,
						render: (h, params) => {
							return h(
								"span", {},
								params.row.userAduiltResult == 0 ?
								"未审核" :
								params.row.userAduiltResult == 1 ?
								"审核通过" :
								"审核不通过"
							);
						},
					},
					{
						title: "是否过期",
						key: "due",
						align: "center",
						width: 120,
						render: (h, params) => {
							return h(
								"span", {},
								// params.row.due ? params.row.due == 0 ? "否" :"是" : '--'
								params.row.due === '' ? '--' : params.row.due === 0 ? '否' : '是'
							);
						},
					},
					{
						title: "是否可登录",
						key: "isCanLogin",
						align: "center",
						width: 120,
						render: (h, params) => {
							return h(
								"span", {},
								params.row.isCanLogin == 0 ? "否" :"是"
							);
						},
					},
					{
						title: "角色",
						key: "roleName",
						align: "center",
						width: 200,
						tooltip: true,
					},
					{
						title: "创建日期",
						key: "gmtCreate",
						align: "center",
						width: 200,
						tooltip: true,
						render: (h, params) => {
							return h("span", {}, formatDate(params.row.gmtCreate));
						},
					},
					{
						title: "更新日期",
						key: "gmtUpdated",
						align: "center",
						width: 200,
						tooltip: true,
						render: (h, params) => {
							return h("span", {}, formatDate(params.row.gmtUpdated));
						},
					},
					{
						title: "操作",
						key: "option",
						align: "center",
						slot: 'option',
						width: 300,
						fixed: 'right',
					},
				],
				data: [],
				showImportPassword: false,
				valid: {
					password: "",
					validCode: "",
				},
				modalTitle: "",
				// 获取账号列表参数
				current: 1,
				size: 10,
				keyword: "",
				phone: '',
				total: 0,
				userId: 0,
				roleId: 0,
				customerName: "",
				selectedRow: {},
				dataAuthList: [],
				funcAuthList: [],
				reportAuthList: [],
				accountType: {
					0: "系统管理员",
					1: "用户管理员",
					2: "普通用户",
					3: "独立管理员",
				},
				checkoutTime: false,
				timeOut: 60,
				tempId: "",
				tempModalName: '',
				userCount: 0,
			};
		},
		computed: {
			isShowAccountCreateModal: {
				get() {
					return this.$store.state.personal.isShowAccountCreateModal;
				},
				set(val) {
					this.$store.state.personal.isShowAccountCreateModal = val;
				},
			},
			isShowAccountEditModal: {
				get() {
					return this.$store.state.personal.isShowAccountEditModal;
				},
				set(val) {
					this.$store.state.personal.isShowAccountEditModal = val;
				},
			},
			isShowAuthModal: {
				get() {
					return this.$store.state.personal.isShowAuthModal;
				},
				set(val) {
					this.$store.state.personal.isShowAuthModal = val;
				},
			},
			isShowEditAuthModal: {
				get() {
					return this.$store.state.personal.isShowEditAuthModal;
				},
				set(val) {
					this.$store.state.personal.isShowEditAuthModal = val;
				},
			},
			realUserId() {
				return this.$store.state.userId;
			},
			customerInfo() {
				let str = localStorage.getItem('customerInfo')
				return JSON.parse(str)
			},
		},
		beforeMount() {
			this.$Notice.destroy();
		},
		mounted() {
			this.getAccount();
			this.getUsableUserCount()
			this.getPackageList()
		},
		methods: {
			async getPackageList() {
				let params = {
					current: 1,
					size: 9999,
					customerId: this.customerInfo.id
				}
				let data = await customer_role_list(params)
				this.permissionsRoleDataList = data.records
			},
			async getUsableUserCount() {
				this.userCount = await usableUserCount({
					customerId: this.customerInfo.id
				})
			},
			deleteAccount(id) {
				deleteAccount({
					userId: id
				}).then(() => {
					this.$msg.success({
						text: "删除成功"
					})
					this.getList(this.current)
				}).catch(() => {
					this.$msg.error({
						text: "删除失败"
					})
				})
			},
			// checkAuth(row) {
			// 	if (row.userAduiltResult != 1) {
			// 		this.$msg.success({
			// 			text: "该账号还未审核"
			// 		})
			// 		return
			// 	}
			// 	this.selectedRow = row;
			// 	if (!row.roleId) {
			// 		this.$msg.success({
			// 			text: "该账号没有授权角色"
			// 		});
			// 	} else {
			// 		this.getAuthList();
			// 	}
			// },
			//发送验证码
			getValidCode() {
				let t = () => {
					this.checkoutTime = true;
					this.timer = setInterval(() => {
						if (this.timeOut == 0) {
							clearInterval(this.timer);
							this.checkoutTime = false;
							this.timeOut = 60;
							return;
						}
						this.timeOut--;
					}, 1000);
				};
				let params = this.$store.state.user.username;
				if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.$store.state.user.username)) {
					getPhoneCode({
							phone: params
						})
						.then(() => {
							t();
						})
						.catch((e) => {
							this.$msg.error({
								text: e,
							});
						});
				} else {
					getCode({
							username: params
						})
						.then(() => {
							t();
						})
						.catch((e) => {
							this.$msg.error({
								text: e,
							});
						});
				}
			},
			createAccount() {
				this.modalTitle = "新建账号";
				this.isShowAccountCreateModal = true;
			},
			importPassword(row, modal) {
				this.showImportPassword = true;
				this.tempId = row.id
				this.rowData = row
				this.tempModalName = modal
			},
			openAuth(row, modal){
				this.tempId = row.id
				this.rowData = row
				this[modal] = true
			},
			importPasswordOk() {
				let params = {
					pwd: this.valid.password,
					// verifyCode: this.valid.validCode
				}
				verifyPwd(params).then(() => {
					this.showImportPassword = false;
					this.editAccount();
					this.valid.password = ''
				}).catch((e) => {
					this.$msg.error({
						text: '密码错误，请输入正确的密码',
					});
				})

			},
			editAccount() {
				this.modalTitle = "查看/编辑账号";
				this[this.tempModalName] = true;
				this.userId = this.tempId;
			},
			getAccount() {
				getDetail(this.realUserId)
					.then((res) => {
						this.customerName = res.customerName;
						if (res.userType != 1) {
							this.$router.push({
								path: "/PersonalCenter"
							});
							this.$msg.error({
								type: "noAuthority",
								code: 1005
							});
						} else {
							this.getList(this.current);
						}
					})
					.catch((err) => {
					});
			},
			getList(current) {
				get_data_user_page({
						current: current,
						size: this.size,
						nickname: this.keyword,
						customerId: this.customerInfo.id,
						deleted: 0,
						phone: this.phone
					})
					.then((res) => {
						this.data = res.records
						this.total = res.total
						this.current = res.current
						this.getUsableUserCount()
					}).catch((err) => {
						this.$msg.error({
							text: err
						});
					});
			},
			pageChange(page) {
				this.getList(page);
			},
			sureUserRole() {
				this.permissionsRoleDataList.forEach(item => {
					if (item.id == this.rowData.roleId) {
						this.rowData.roleName = item.roleName
					}
				})
				editAccount(this.rowData).then(res => {
					this.$msg.success({
						text: '授权成功',
					})
					this.getList(this.current)
					this.showUserRoleList = false
				})
			},
			cancleUserRole() {
				this.showUserRoleList = false
			}
		},
	};
</script>

<style lang="scss" scoped>
	.my-account {
		width: 100%;
		padding: 0 20px;

		.search {
			padding: 25px 0;
			display: flex;

			.left {
				margin-right: 80px;

				.name {
					margin-right: 20px;
				}
			}

			.right {
				.btn {
					width: 114px;

					&:first-child {
						margin-right: 20px;
					}
				}
			}
		}

		.table {
			& /deep/ .ivu-table-cell {
				padding-right: 10px;
				padding-left: 10px;
			}
		}

		.optionBtn {
			margin-right: 5px;
		}

		.canUseaccount {
			margin: 15px 0;
		}
	}

	/deep/.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}

	.mainContent {
		padding: 0 50px;

		.RadioGroupClass {
			width: 100%;
			max-height: 120px;
			overflow-y: auto;
			border: 1px solid rgba($color: #999999, $alpha: 0.5);
			padding: 10px;
			border-radius: 8px;
			margin: 10px 0;
		}
	}
	.roleList{
		font-size: 14px;
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		text-align: left;
		color: #333333;
	}
</style>
