<template>
	<Modal v-model="show" footer-hide :title='title' class="create-modal" class-name="vertical-center-modal"
		:width="700" :mask-closable="false">
		<!-- 		<p class="head">
			<span class="icon"></span>
			<span class="text">{{ title }}</span>
		</p> -->
		<div class="modal-content">
			<Form :model="createForm" :label-width="80" class="create-form" :rules="createRules" ref="createForm">
				<FormItem label="姓名" prop="name">
					<Input v-model="createForm.name" maxlength="15" show-word-limit placeholder="请输入"
						style="width: 100%"></Input>
				</FormItem>
				<FormItem label="手机号码" prop="account">
					<Input v-model="createForm.account" placeholder="请输入" style="width: 100%;" show-word-limit
						maxlength="11"></Input>
				</FormItem>
				<FormItem label="密码" prop="password">
					<Input :disabled="true" password type="password" placeholder="初始密码为123456"
						style="width: 100%;"></Input>
				</FormItem>

				<FormItem label="性别" prop="gender">
					<RadioGroup style="width: 100%" v-model="createForm.gender">
						<Radio v-for="item in genderList" :label="item.value" :key="item.value">{{item.label}}
						</Radio>
					</RadioGroup>
				</FormItem>
				<FormItem label="职务/部门" prop="jobs">
					<Input v-model="createForm.jobs" maxlength="30" show-word-limit placeholder="请输入"
						style="width: 100%"></Input>
				</FormItem>
				<hr
					style="background-color: gray;border: none;height: 1px;opacity: 0.5;width: 580px;position: relative;left: 50%;transform: translateX(-50%);">
				</hr>
				<!-- 	<FormItem style='margin-top: 40px;' label="账号状态" prop="status">
					<RadioGroup v-model="createForm.status" style="width: 100%">©
						<Radio label="1">可用</Radio>
						<Radio label="0">不可用</Radio>
					</RadioGroup>
				</FormItem> -->
				<FormItem style='margin-top: 40px;' label="账号授权" prop="roleId">
					<Button type="info" @click="addRole">点击授权</Button><span
						style="margin-left: 10px;">{{createForm.roleName === '' ? '请选择角色':`角色名称：${createForm.roleName}`}}</span>
				</FormItem>
				<!-- 屏蔽有效日期选项 -->
				<!-- 	<FormItem v-if="customerInfo.customerState ==0 || customerInfo.customerState ==3" label="有效日期" require
					prop="accountBeginDate">
					<DatePicker :editable='false' :options="DateOptions" style="width: 150px"
						v-model="createForm.accountBeginDate" placeholder="起始时间"></DatePicker>
					至
					<DatePicker :editable='false' :options="DateOptions" style="width: 150px"
						v-model="createForm.accountEndDate" placeholder="到期时间"></DatePicker>
				</FormItem> -->
				<FormItem style="text-align: center;margin-top: 60px;" :label-width="0">
					<Button type="info" style="width: 140px;height: 40px;"
						@click="handleSubmit('createForm')">确定</Button>
					<Button type="default" style="width: 140px;margin-left: 20px;height: 40px;" class="cancel"
						@click="handleCancel()">取消</Button>
				</FormItem>
			</Form>
		</div>
		<Modal class-name="vertical-center-modal" v-model="showUserRoleList" title="账号授权" width="610" :closable='false'
			:mask-closable="false" :footer-hide='true'>
			<div class="mainContent">
				<Form ref="formInline" inline>
					<FormItem v-if='createForm.name' style='width: 47%;' :label-width="40" label="姓名">
						<Input :readonly="true" v-model="createForm.name" placeholder="请输入"></Input>
					</FormItem>
					<FormItem v-if='createForm.account' style='width: 47%;' :label-width="80" label="手机号码">
						<Input :readonly="true" v-model="createForm.account" placeholder="请输入"></Input>
					</FormItem>
				</Form>
				<div class="roleList">
					角色列表
				</div>
				<RadioGroup class='RadioGroupClass' v-model="createForm.roleId">
					<Radio style='width: 45%;margin: 10px;' v-for="(item,index)  in permissionsRoleDataList"
						:key='index' :label="item.id">
						<span>{{item.roleName}}</span>
					</Radio>
				</RadioGroup>
			</div>

			<div class="footer" style="text-align: center;margin: 60px 0 40px;">
				<Button style="width: 140px;height: 40px;" type="info" @click="sureUserRole">确定</Button>
			</div>
		</Modal>
	</Modal>
</template>

<script>
	import {
		addAccount,
		checkRepeat,
		customer_role_list
	} from "@/api/roleAndAccount";
import {
	reg_phone
} from "@/data/reg.js"
	export default {
		name: "createModal",
		props: {
			title: {
				type: String,
				default: "",
			},
		},
		data() {
			const validatePhone = (rule, value, callback) => {
				const reg = reg_phone
				if (value === "") {
					callback(new Error("此项必填"));
				} else if (!reg.test(value)) {
					callback(new Error("请输入正确的手机号码"));
				} else {
					checkRepeat({
						phone: value,
					}).then((res) => {
						if (!res) {
							callback('该手机号已存在')
						} else {
							callback()
						}
					});

				}
			};

			const validateEmail = (rule, value, callback) => {
				const reg =
					/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
				if (value === "") {
					callback(new Error("此项必填"));
				} else if (!reg.test(value)) {
					callback(new Error("请输入正确的邮箱"));
				} else {
					callback();
				}
			};



			const validatePassword = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("此项必填"));
				} else if (value.length < 6) {
					callback(new Error("密码长度至少6位"));
				} else {
					callback();
				}
			};

			return {
				DateOptions: {},
				permissionsRoleDataList: [],
				showUserRoleList: false,
				createForm: {
					account: "",
					name: "",
					gender: "",
					jobs: "",
					// workYears: "",
					// weChat: "",
					email: "",
					// status: "1",
					isExist: false,
					roleId: '',
					roleName: '',
					// accountBeginDate: '',
					// accountEndDate: '',
				},
				genderList: [{
						value: 0,
						label: "男",
					},
					{
						value: 1,
						label: "女",
					},
				],
				createRules: {
					account: [{
						required: true,
						validator: validatePhone,
						trigger: "blur"
					}],
					name: [{
						required: true,
						message: "此项必填",
						trigger: "blur"
					}],
					// gender: [{
					// 	required: true,
					// 	message: "此项必填",
					// }],
					// jobs: [{
					// 	required: true,
					// 	message: "此项必填",
					// 	trigger: "blur"
					// }],
					// workYears: [{
					// 	required: true,
					// 	message: "此项必填",
					// 	trigger: "blur"
					// }],
					// telephone: [
					//   { required: true, validator: validatePhone, trigger: "blur" },
					// ],
					// status: [{
					// 	required: true,
					// 	message: "此项必填",
					// 	trigger: "blur"
					// }],
					roleId: [{
						required: true,
						message: "请选择角色",
					}],
					// accountBeginDate: [{
					// 	required: false,
					// 	message: "此项必填",
					// }],
					// accountEndDate: [{
					// 	required: false,
					// 	message: "此项必填",
					// }],
				},
			};
		},
		computed: {
			show: {
				get() {
					return this.$store.state.personal.isShowAccountCreateModal;
				},
				set(val) {
					this.$store.state.personal.isShowAccountCreateModal = val;
				},
			},
			customerInfo() {
				let str = localStorage.getItem('customerInfo')
				let obj = JSON.parse(str)
				return obj
			},
		},
		watch: {
			show(val) {
				if (val) {
					this.getPackageList()
					// this.setRules(this.customerInfo)
				} else {
					this.reSet()
				}
			},
		},
		methods: {
			reSet() {
				this.createForm = {
					account: "",
					name: "",
					gender: "",
					jobs: "",
					// weChat: "",
					email: "",
					// status: "1",
					isExist: false,
					roleId: '',
					roleName: '',
					// accountBeginDate: '',
					// accountEndDate: '',
				}
			},
			setRules(obj) {
				//当状态为试用&流失试用时，时间为必选项
				if (obj.customerState == 0 || obj.customerState == 3) {
					this.createRules.accountBeginDate[0].required = true
					this.createRules.accountEndDate[0].required = true
				} else {
					this.createRules.accountBeginDate[0].required = false
					this.createRules.accountEndDate[0].required = false
				}
				//初始化禁用时间
				this.DateOptions = {
					disabledDate(date) {
						return date && date.valueOf() < new Date(obj.customerAccountBeginDate) || date.valueOf() >
							new Date(obj.customerAccountEndDate)
					}
				}
			},
			async getPackageList() {
				let params = {
					current: 1,
					size: 9999,
					customerId: this.customerInfo.id
				}
				let data = await customer_role_list(params)
				this.permissionsRoleDataList = data.records
			},
			addRole() {
				this.showUserRoleList = true
			},
			sureUserRole() {
				this.permissionsRoleDataList.forEach(item => {
					if (item.id == this.createForm.roleId) {
						this.createForm.roleName = item.roleName
					}
				})
				this.showUserRoleList = false
			},
			handleSubmit(name) {
				if (this.isExist) {
					// this.$Message.warning('账号名已存在');
					this.$msg.error({
						text: "该用户账号名已存在"
					});

					return;
				}
				this.$refs[name].validate((valid) => {
					if (valid) {
						if (new Date(this.createForm.accountBeginDate).valueOf() > new Date(this.createForm
								.accountEndDate).valueOf()) {
							this.$msg.error({
								text: "时间选择错误"
							});
							return
						}
						addAccount({
							phone: this.createForm.account,
							nickname: this.createForm.name,
							password: 123456,
							sex: this.createForm.gender,
							userDepartment: this.createForm.jobs,
							// userWorktime: this.createForm.workYears,
							// userWechat: this.createForm.weChat,
							email: this.createForm.email,
							// state: this.createForm.status,
							userType: 2,
							roleId: this.createForm.roleId,
							roleName: this.createForm.roleName,
							accountBeginDate: new Date(this.createForm.accountBeginDate).valueOf(),
							accountEndDate: new Date(this.createForm.accountEndDate).valueOf(),
							origin:1,//数据来源 0内部治理 1数据平台 2H5 3小程序
						}).then((res) => {
							this.show = false;
							this.reset();
							this.$msg.success({
								text: "创建成功，审核中…"
							});
							this.$emit("done");
						}).catch(err => {

							if (err.code == -1) {
								this.$msg.error({
									text: err.data
								});
							}else{
								this.$msg.error({
									text: "创建失败"
								});
							}
						})
					} else {
						this.$msg.error({
							text: '输入项有误，请检查后重新输入'
						});
					}
				});
			},
			handleCancel() {
				this.reset();
				this.show = false;
			},
			reset() {
				this.createForm = {
					account: "",
					name: "",
					gender: "",
					jobs: "",
					// workYears: "",
					// telephone: "",
					// weChat: "",
					email: "",
					// status: "1",
				};
			},
			handleCheckName(name) {
				if (!name.replace(/\s+/g, "")) {
					this.createForm.account = "";
					this.$msg.error({
						text: "请输入用户账号"
					});
					return;
				}
				checkRepeat({
					username: name,
				}).then((res) => {
					if (res === "存在") {
						this.isExist = true;
						// this.$Message.warning('账号名已存在');
						this.$msg.error({
							text: "该用户账号名已存在"
						});
					} else {
						this.isExist = false;
						this.$msg.success({
							text: "恭喜，账号可用。"
						});
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.create-modal {
		/deep/ .vertical-center-modal {
			display: flex;
			align-items: center;
			justify-content: center;

			.ivu-modal {
				top: 0;
			}

			.ivu-modal-body {
				padding: 0;
				height: 100%;
			}

			.head {
				width: 100%;
				padding-top: 20px;
				padding-left: 20px;
				display: flex;
				align-items: center;

				.icon {
					display: inline-block;
					width: 4px;
					height: 23px;
					background-color: #00b6ff;
					position: relative;

					&::after {
						content: "";
						border: 4px solid transparent;
						border-left-color: #00b6ff;
						position: absolute;
						top: 50%;
						right: -7px;
						margin-top: -4px;
					}
				}

				.text {
					margin-left: 6px;
					font-size: 18px;
					font-weight: bold;
					line-height: 20px;
					color: #333;
				}
			}

			.modal-content {
				padding: 40px 130px;
			}

		}
	}

	/deep/.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}

	.mainContent {
		padding: 0 50px;

		.RadioGroupClass {
			width: 100%;
			max-height: 120px;
			overflow-y: auto;
			border: 1px solid rgba($color: #999999, $alpha: 0.5);
			padding: 10px;
			border-radius: 8px;
			margin: 10px 0;
		}
	}

	.roleList {
		font-size: 14px;
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		text-align: left;
		color: #333333;
	}
</style>
